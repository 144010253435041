.title-cookie-policy {
    font-size: 3.2em;
    font-family: 'Roboto Condensed';
    font-weight: 300;
    text-transform: uppercase;
}

.paragraph-cookie-policy {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    line-height: 1.9;
    color: rgb(17, 17, 17);
}

.subtitle-cookie-policy {
    text-transform: uppercase;
}

.div-cookie-policy {
    margin-left: 500px;
    margin-right: 500px;
    padding-top: 100px;
    padding-bottom: 50px;
}

@media screen and (max-width: 600px) {
    .div-cookie-policy {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }
}

@media screen and (max-width: 1500px) {
    .div-cookie-policy {
        margin-left: 150px;
        margin-right: 150px;
    }
}
