.topBar {
    background-color: #e8e8e8;
    border-bottom: 5px solid #e8e8e8;
    border-top: 5px solid #e8e8e8;
    color: #212121;
    padding-bottom: 10px;
}

.topBar-arrow-container {
    display: flex;
    cursor: pointer;
}

.topBar-arrow {
    transition: 500ms;
    font-size: 34px;
    height: 35px;
}

.topBar-arrow path {
    transition: 500ms;
    fill: black;
}

.topBar-arrow.right {
    display: flex;
    margin-left: auto;
}
