.remoteVideo {
    display: none;
    position: relative;
    background: transparent;
    overflow: hidden;
}

.remoteVideo.roomMode {
    border-radius: 0.25rem;
}

.remoteVideo.screenShareMode {
    border-radius: 0.25rem;
}

.remoteVideo.enabled {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.remoteVideo .video {
    display: none;
}

.remoteVideo .video.enabled {
    display: flex;
    width: 100%;
    height: 100%;
    /*
    object-fit: contain --> resize del video
    object-fit: cover --> video centrato viene tagliato l'overflow ai lati
    */
    object-fit: contain;
}

.raisedHand {
    z-index: 1;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    font-size: 2rem;
    animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    user-select: none;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-0.5px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-1.5px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(1.5px, 0, 0);
    }
}
