.documentNameAndSize {
    font-size: 30px;
    left: 0;
    font-family: 'Roboto', sans-serif !important;
}

.uploadButtonDocumentContainer {
    margin-top: 100px;
    display: flex;
    align-content: center;
    width: 100%;
}

@media screen and (max-width: 500px) {
    .uploadButtonDocumentContainer {
        margin-top: 20px;
    }
}

.actionPar_red_doc {
    font-size: 34px;
    font-weight: bold !important;
    color: red !important;
    font-family: 'Roboto', sans-serif !important;
    opacity: 0.8 !important;
}

.actionPar_black_doc {
    font-size: 34px;
    font-weight: bold !important;
    color: black !important;
    font-family: 'Roboto', sans-serif !important;
    opacity: 0.8 !important;
}

@media screen and (max-width: 400px) {
    .actionPar_black_doc {
        font-size: 25px;
    }
}

.inputFieldDocument {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid #000 !important;
    height: 36px !important;
    background-color: #e8e8e8 !important;
    padding: 0 !important;
}

.inputFieldDocument:hover {
    border-radius: 0;
    border-color: #000 !important;
    border: none !important;
    border-bottom: 1px solid #000;
    background-color: #0000000c;
    outline: 0;
    box-shadow: none;
}

.inputFieldDocument > .ant-input:focus {
    border: none !important;
}

.inputFieldDocument > .ant-input-focused {
    border: none !important;
}
