@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@1,100&family=Roboto:wght@100;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.datatitle {
    font-size: 25px;
    color: #232323;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
}

@media screen and (max-width: 30em) {
    .datatitle {
        font-size: 20px;
    }
}

.icon {
    font-size: 70px;
}

@media screen and (max-width: 30em) {
    .icon {
        font-size: 60px;
    }
}

.datatext {
    font-family: Arial, sans-serif;
    font-size: 22px;
    text-align: left;
    line-height: 1.6;
}

@media screen and (max-width: 30em) {
    .datatext {
        font-size: 20px;
    }
}

.div {
    margin-top: 50px;
    margin-bottom: 50px;
}

.div-aderire {
    margin-top: 60px;
}

@media screen and (max-width: 180em) {
    .div-aderire {
        margin-left: 160px;
        margin-right: 160px;
    }
}

@media screen and (max-width: 118em) {
    .div-aderire {
        margin-left: 160px;
        margin-right: 160px;
    }
}

@media screen and (max-width: 60em) {
    .div-aderire {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media screen and (max-width: 30em) {
    .div-aderire {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.titolo {
    color: #232323;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 3rem;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bolder;
    letter-spacing: -1px;
}

@media screen and (max-width: 30em) {
    .titolo {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.paragrafo {
    color: #232323;
    text-align: left;
    font-family: Arial, sans-serif;
    font-size: 24px;
}

@media screen and (max-width: 118em) {
    .paragrafo {
        margin-left: 160px;
        margin-right: 160px;
    }
}

@media screen and (max-width: 190em) {
    .paragrafo {
        margin-left: 160px;
        margin-right: 160px;
    }
}

@media screen and (max-width: 70em) {
    .paragrafo {
        margin-left: 60px;
        margin-right: 60px;
    }
}

@media screen and (max-width: 30em) {
    .paragrafo {
        margin-left: 60px;
        margin-right: 40px;
        font-size: 20px;
    }
}

.carattitle {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    text-transform: uppercase;
}

.carattext {
    font-size: 24px;
    color: white;
    font-family: 'Roboto', sans-serif;
    text-align: left;
}

@media screen and (max-width: 30em) {
    .carattext {
        font-size: 20px;
    }
}

.section-caratteristiche {
    background: #232323;
    padding-top: 2px;
    padding-bottom: 60px;
}

.App-elenco {
    border: 2px solid #efefef;
    border-radius: 50%;
    width: 88px;
    height: 88px;
    font-size: 44px;
    color: #ffffff;
    justify-content: center;
}

@media screen and (max-width: 30em) {
    .div-divider {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 70em) {
    .div-divider {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
}

@media screen and (max-width: 110em) {
    .div-divider {
        margin-left: 160px;
        margin-right: 160px;
    }
}

@media screen and (max-width: 190em) {
    .div-divider {
        margin-left: 160px;
        margin-right: 160px;
    }
}

.div-card {
    margin-left: 150px;
    margin-right: 150px;
}

@media screen and (max-width: 700px) {
    .div-card {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.mbr-overlay {
    position: relative;
    right: 0;
    top: 0;
    z-index: 0;
    /* pointer-events: none; */
    bottom: 0;
    left: 0;
    background-color: rgba(35, 35, 35, 0.7);
    height: auto;
    padding: 40px;
}

.h1-parallax {
    text-align: center;
    color: #ffffff;
    font-family: 'Exo', sans-serif;
    font-size: 4.5em;
    margin-bottom: 0px;
}

@media screen and (max-width: 90em) {
    .h1-parallax {
        font-size: 4em;
    }
}

@media screen and (max-width: 80em) {
    .h1-parallax {
        font-size: 3.7em;
    }
}

@media screen and (max-width: 50em) {
    .h1-parallax {
        font-size: 3em;
    }
}

@media screen and (max-width: 30em) {
    .h1-parallax {
        font-size: 2.2rem;
    }
}

.p-parallax {
    color: #ffffff;
    font-size: 3rem;
    font-family: 'Exo', sans-serif;
    font-weight: 400;
    margin-left: 40px;
    margin-right: 40px;
}

@media screen and (max-width: 70em) {
    .p-parallax {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 60em) {
    .p-parallax {
        font-size: 2rem;
    }
}

@media screen and (max-width: 30em) {
    .p-parallax {
        font-size: 1.7rem;
    }
}

.sezione-carosello {
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center center;
    min-height: 100vh;
}

@media screen and (max-width: 70em) {
    .sezione-carosello {
        min-height: 600px;
    }
}

@media screen and (max-width: 40em) {
    .sezione-carosello {
        min-height: 300px;
    }
}

.container-text-slider {
    margin: 100px;
    text-align: left;
}

@media screen and (max-width: 70em) {
    .container-text-slider {
        margin: 15px;
    }
}

.text-slider {
    font-size: 5rem;
    color: #ffffff;
    line-height: 1;
    font-weight: 400;
    font-family: 'Exo', sans-serif !important;
    text-transform: uppercase;
}

@media screen and (max-width: 70em) {
    .text-slider {
        font-size: 3.5em;
    }
}

@media screen and (max-width: 40em) {
    .text-slider {
        font-size: 1.6rem;
    }
}

.subtitle_text_slider {
    font-size: 2rem;
    font-family: 'Exo', sans-serif;
    font-weight: 350;
    color: #fff;
    margin-top: 20px;
}

@media screen and (max-width: 40em) {
    .subtitle_text_slider {
        font-size: 1rem;
    }
}

.ant-card.homepage-card {
    background-color: #fff;
    width: 90%;
    height: 100%;
    border-radius: 25px;
}

@media screen and (max-width: 1300px) {
    .ant-card.homepage-card {
        width: 100%;
    }
}

@media screen and (max-width: 300px) {
    .ant-card.homepage-card {
        width: 50%;
    }
}

.card-h1 {
    text-align: center;
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8rem !important;
}

.card-p {
    font-size: 1.3rem !important;
    text-align: left !important;
    font-family: 'Open Sans', sans-serif;
}

.a-footer {
    color: #df2727 !important;
}

.a-footer:hover {
    color: #8d1111 !important;
}

.section-footer {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #ffffff;
}

.homepageBtn {
    background-color: #df2727 !important;
    border-radius: 4px !important;
    border-color: #df2727 !important;
    padding-top: 15px !important;
    padding-bottom: 45px !important;
    width: auto;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 20px !important;
}

.homepageBtn:hover {
    background-color: #8d1111 !important;
    border-color: #8d1111 !important;
    color: #ffffff !important;
}

.homepageBtn-login {
    background-color: #df2727 !important;
    border-radius: 4px !important;
    border-color: #df2727 !important;
    padding-top: 15px !important;
    padding-bottom: 45px !important;
    width: auto;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 20px !important;
}

.homepageBtn-login:hover {
    background-color: #8d1111 !important;
    border-color: #8d1111 !important;
    color: #ffffff !important;
}

.carousel-arrow {
    background-color: #df2727 !important;
    border-color: #df2727 !important;
    width: 60px !important;
    height: 60px !important;
    opacity: 0.7;
}

@media screen and (max-width: 50em) {
    .carousel-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px !important;
        height: 40px !important;
    }
}

@media screen and (max-width: 30em) {
    .carousel-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px !important;
        height: 30px !important;
    }
}

.carousel-arrow:hover {
    background-color: #df2727 !important;
    border-color: #df2727 !important;
    width: 65px !important;
    height: 65px !important;
    opacity: 1;
}

@media screen and (max-width: 50em) {
    .carousel-arrow:hover {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px !important;
        height: 40px !important;
    }
}

@media screen and (max-width: 30em) {
    .carousel-arrow:hover {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px !important;
        height: 30px !important;
    }
}

.arrows-ant-carousel-spot {
    color: white !important;
    font-size: 35px !important;
    line-height: 0 !important;
    margin-left: 0 !important;
}

@media screen and (max-width: 50em) {
    .arrows-ant-carousel-spot {
        font-size: 25px !important;
    }
}

@media screen and (max-width: 30em) {
    .arrows-ant-carousel-spot {
        font-size: 20px !important;
    }
}

.arrow-iscritti {
    width: 40px;
    height: 40px;
    margin-right: 20px !important;
    fill: #727070;
    cursor: pointer;
}

.arrow-iscritti:hover {
    width: 40px;
    height: 40px;
    margin-right: 20px !important;
    fill: #363636;
    cursor: pointer;
}

.linkedin-footer,
.facebook-footer,
.twitter-footer,
.instagram-footer,
.youtube-footer {
    width: 32px;
    margin: 5px;
    fill: #000;
    background-color: #fff;
}

.linkedin-footer:hover {
    fill: #0073b1;
    background-color: #000;
}

.facebook-footer:hover {
    fill: #2c51b6;
    background-color: #000;
}

.twitter-footer:hover {
    fill: #2962ff;
    background-color: #000;
}

.instagram-footer:hover {
    fill: #b62135;
    background-color: #000;
}

.youtube-footer:hover {
    fill: #ff0000;
    background-color: #000;
}

.p-footer {
    color: #000000;
    font-size: 14px;
}

.video_live {
    width: 300px;
    height: 300px;
}

.titolo-live {
    color: #232323;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 3rem;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bolder;
    letter-spacing: -1px;
}

p:first-child.video-live-title {
    font-size: 20px;
    color: #232323;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    margin-top: 20px;
    margin-left: 10px;
}

.video-live-company {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-top: -20px;
}

.live-comp {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.live-container-company {
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 25px;
}

.live-container-company video {
    border-radius: 25px;
}

@media screen and (max-width: 30em) {
    .live-container-company {
        width: 300px;
        height: 300px;
    }
}

.live-container-position {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.video-live-div {
    height: auto;
    margin-top: 20px;
}

.carouselModelLive .slick-track {
    display: flex !important;
    align-items: center;
}

.icon-section-whyjoin {
    width: 100px;
    height: 100px;
}

.companies-name-div {
    width: 350px;
    height: auto;
}

.companies_name_paragraph {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 120em) {
    .row-elenco-whyjoin {
        width: 100%;
        margin-left: 90px;
    }
}

@media screen and (max-width: 80em) {
    .row-elenco-whyjoin {
        margin-left: 50px;
    }
}

@media screen and (max-width: 60em) {
    .row-elenco-whyjoin {
        margin-left: 50px;
    }
}

@media screen and (max-width: 30em) {
    .row-elenco-whyjoin {
        margin-left: 20px;
    }
}

.companies-container-company {
    width: 300px;
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.companies-container-position {
    display: flex;
    justify-content: center;
    width: auto;
    overflow: hidden;
}

.arrow-modal-live {
    cursor: pointer;
    width: 60px;
}

@media screen and (max-width: 30em) {
    .arrow-modal-live {
        width: 30px;
    }
}

@media screen and (max-width: 50em) {
    .arrow-modal-live {
        width: 40px;
    }
}

.carousel-homepage {
    min-height: 100vh;
}

@media screen and (max-width: 70em) {
    .carousel-homepage {
        min-height: 600px;
    }
}

@media screen and (max-width: 40em) {
    .carousel-homepage {
        min-height: 300px;
    }
}
