.xg_main {
    transition: max-height 500ms ease-in;
    padding: 0 145px;
    max-height: unset;
}

.xg_main_close {
    transition: max-height 500ms ease-out;
    max-height: 533px;
    overflow: hidden;
}

.xg_header {
    border-bottom: 3px solid black;
    margin: 50px 0 0;
    color: #212121;
    border-radius: 10px 10px 0 0;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, transparent 50%, black 50%);
    transition: background-position 150ms ease-in-out, color 150ms ease-in-out;
}

.xg_header_open {
    background-position: 0 100%;
    color: white;
    border-radius: 10px 10px 0 0;
}

.xg_content {
    padding: 50px 0 0;
    background-size: 100% 200%;
    background-position: 0 100%;
    background-image: linear-gradient(to bottom, #cccccc 50%, transparent 50%);
    transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;
}

.xg_content_open {
    background-position: 0 0;
    border-radius: 0 0 10px 10px;
}

.xg_header_row {
    height: 50px;
    padding: 0 20px;
}

.xg_title {
    text-align: start;
    text-transform: uppercase;
}

.xg_title_p {
    transition: 500ms;
    line-height: 50px;
    font-size: 34px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.xg_arrow {
}

.xg_tile {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.xg_plus_button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media (max-width: 576px) {
    .xg_main {
        transition: 500ms;
        padding: 0 15px;
    }
}

@media (max-width: 768px) {
    .xg_main {
        transition: 500ms;
        padding: 0 25px;
    }

    .xg_title_p {
        line-height: 50px;
        font-size: 16px;
    }
}

/*
@media(min-width:992px) {

}

@media(min-width:1200px) {

}

@media(min-width:1600px) {

}*/
