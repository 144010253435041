@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@1,100&family=Roboto:wght@100;500;700&display=swap');

div.company_acceptance.panelHeaderContainer,
div.company_acceptance.card_container {
    background-color: #e8e8e8;
}

.company_acceptance.card_container {
    padding: 40px;
    min-height: 480px;
}

@media screen and (max-width: 500px) {
    .company_acceptance.card_container {
        padding: 10px;
    }
}

.company_acceptance button[type='submit']:hover {
    border: none;
    background-color: #129a2d;
}

.company_description {
    border: 1px solid #eaeaea !important;
    padding: 15px 50px 10px 90px;
    background-color: #ffffff;
    font-size: 16px;
}

@media screen and (max-width: 500px) {
    .company_description {
        padding: 15px 20px 10px 60px;
    }
}

.company_description_header {
    padding: 20px 50px 10px 50px;
    background-color: #ffffff;
}

@media screen and (max-width: 500px) {
    .company_description_header {
        padding: 20px 30px 10px 30px;
    }
}

.approve_button {
    border: 1px solid #eaeaea !important;
    padding: 25px 50px 25px 50px;
    background-color: #ffffff;
    width: 100%;
    margin: auto;
}

.card_row {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #000 !important;
}
.company_approved {
    color: #159a2e;
}

.ui.label.label-info {
    padding: 0;
    background-color: #ffffff;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #5c5c5c;
    display: flex;
    align-items: center;
}

.company_description_label {
    border: 1px solid #eaeaea !important;
    padding: 15px 50px;
    background-color: #ffffff;
}

@media screen and (max-width: 500px) {
    .company_description_label {
        padding: 5px 20px;
    }
}

.panding_company_name {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
}

.collapse-card-approvazione .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
}

.collapse-card-approvazione .ant-collapse-header {
    background-color: #fff;
    color: #adadad !important;
    font: Bold 20px Roboto;
    text-align: center;
    box-shadow: 0px 5px 5px #00000029;
    padding-left: 0px !important;
}

.contain-company-acceptance {
    border: 4px solid #707070;
    margin: 30px 0px;
    border-radius: 16px;
    box-shadow: 0px -4px 8px #00000029;
}

.company-name {
    background: transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font: Bold 30px Roboto;
    text-align: center;
    padding: 9px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.contain-text-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 27px;
    justify-content: center;
}

.icon-arrow {
    font-size: 22px !important;
    left: 54% !important;
}

@media screen and (max-width: 1500px) {
    .icon-arrow {
        left: 56% !important;
    }
}

@media screen and (max-width: 500px) {
    .icon-arrow {
        left: 60% !important;
    }
}
