.mainLayoutNewPage {
    background-color: #e8e8e8 !important;
    -ms-overflow-style: none;
}

.mainLayoutNewPage::-webkit-scrollbar {
    display: none;
}

.headerBar {
    background-color: white;
    width: 100%;
    height: 180px;
    box-shadow: 0 4px 8px #00000029;
    padding: 0 45px;
}

.headerLogo {
    padding: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.footerLogo {
    width: 300px;
}

.headerButton {
    text-align: center;
    display: flex;
    align-items: center;
}

.chamberName {
    font-family: Roboto, sans-serif;
    font-size: 34px;
    text-align: center;
    font-weight: 500;
    padding: 5px;
    color: black;
}

.companyTop {
    z-index: 150;
    box-shadow: 0 4px 8px #00000029;
    background-color: white;
}

.companyDetails {
    transition: 500ms;
    z-index: 100;
    background-color: white;
    padding: 100px 0;
}

.companyTopRow {
    box-shadow: 0 4px 8px #00000029;
}

.companySection {
    box-shadow: 0 4px 8px #00000029;
}

.companyImageContainer {
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.companyImage {
    width: 100%;
}

.companyInfo {
    min-height: 600px;
    padding: 40px 100px;
}

.companyName {
    font-size: 34px;
    line-height: 34px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #212121;
}

.companyPlace {
}

.companySocials {
    margin-top: 35px;
}

.companyDescription {
    padding: 35px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #212121;
}

.companyTags {
}

.companyTags p {
    float: left;
    padding: 10px;
    margin: 5px;
    background-color: #e8e8e8;
    border-radius: 20px;
    color: #808080;
}

.otherCompany {
    height: 550px;
    padding: 50px 145px;
}

.topBarButton {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.otherCompanyTitle {
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    font-family: 'Roboto', sans-serif;
}

.otherCompanyLogoLeft,
.otherCompanyLogoRight {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.otherCompanyLogos {
    height: 400px;
    background-color: #4aa061;
}

.askEvent {
    height: 200px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.askEventButton {
    transition: 500ms;
    width: 700px;
    height: 100px;
    background-color: #212121;
    color: white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.askEventButton:hover {
    transition: 500ms;
    color: #212121;
    background-color: white;
    border: 3px solid #212121;
}

.footerBar {
    transition: 500ms;
    height: 260px;
    background-color: #212121;
    box-shadow: 0 -4px 8px #00000029;
    color: white;
    padding: 0 45px;
}

.matchingScore {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    color: white;
}

.ui.label.expo_tag {
    border-radius: 25px;
    margin: 7px;
    font-size: 14px;
}

.tagsBar {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 4px 8px #00000029;
    opacity: 1;
    padding: 25px 0 10px 0;
    width: 100%;
    height: 100px;
}

/*
@media(max-width:576px) {

}
@media(min-width:576px) {

}
*/
@media (max-width: 768px) {
    .headerBar {
        height: 90px;
        padding: 0 10px;
    }

    .companyInfo {
        padding: 40px 25px;
    }

    .companyDetails {
        transition: 500ms;
        padding: 0;
    }

    .askEvent {
        transition: 500ms;
        margin: 0;
    }

    .askEventButton {
        transition: 500ms;
        width: 300px;
        height: 75px;
        font-size: 24px;
    }

    .footerBar {
        transition: 500ms;
        padding: 0 10px;
    }

    .footerLogo {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .headerBar {
        height: 130px;
        padding: 0 10px;
    }
}

@media (max-width: 900px) {
    .headerBar {
        height: 120px;
        padding: 0 10px;
    }
}

@media (max-width: 700px) {
    .headerBar {
        height: 100px;
        padding: 0 10px;
    }
}

@media (max-width: 450px) {
    .headerBar {
        height: 80px;
        padding: 0 10px;
    }
}
/*
@media(min-width:992px) {

}
*/
@media (min-width: 1200px) {
    .companyImage {
        width: auto;
        height: 100%;
    }
}
/*
@media(min-width:1600px) {

}*/
.company .xg_tile_image img {
    /*cursor: pointer;*/
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    object-fit: contain;
}
.xg_tile_image img {
    /*cursor: pointer;*/
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    object-fit: cover;
}
.xg_tile_image video {
    /*cursor: pointer;*/
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    object-fit: cover;
}

.tagModalTitle {
    height: 50px;
    background: black;
    color: white;
    font-size: 34px;
    text-align: center;
    font-weight: 500;
    padding: 5px;
}

@media screen and (max-width: 600px) {
    .tagModalTitle {
        height: 95px;
        font-size: 30px;
    }
}

.tagModalSubTitle {
    margin-top: 20px;
    height: 45px;
    color: black;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    padding: 5px;
}

@media (max-width: 50em) {
    .tagModalSubTitle {
        height: 130px;
        font-size: 21px;
    }
}

.profile {
    position: absolute;
    transform: translateY(-120%);
    margin-left: 4%;
    width: 170px;
    height: 170px;
    overflow: hidden;
    z-index: 1;
    background-color: white;
    outline: 4px solid #ebebeb;
    align-items: center;
    justify-content: center;
    display: flex;
}

.profile img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 170px;
    height: 170px;
    object-fit: contain;
}

.div-tag-company {
    min-height: 40px;
    margin-right: 160px;
    margin-left: 160px;
    margin-top: 30px;
}

@media screen and (max-width: 600px) {
    .div-tag-company {
        margin-right: 30px;
        margin-left: 30px;
        margin-top: 30px;
    }
}

.editIcon.tag_company {
    margin-top: 7px;
    position: absolute;
    margin-left: 7px;
}

@media screen and (max-width: 430px) {
    .editIcon.tag_company {
        position: relative;
    }
}

@media screen and (max-width: 800px) {
    .editIcon.tag_company {
        margin-left: 10px;
    }
}

.carouselModelLive .slick-track {
    display: flex !important;
    align-items: center;
}

.arrow-modal-live {
    cursor: pointer;
    width: 60px;
}

@media screen and (max-width: 30em) {
    .arrow-modal-live {
        width: 30px;
    }
}

@media screen and (max-width: 50em) {
    .arrow-modal-live {
        width: 40px;
    }
}
.modalTagResponsive.ui.modal {
    background-color: white;
    min-height: 500px;
}

@media screen and (max-width: 2000px) {
    .modalTagResponsive.ui.modal {
        width: 80vw !important;
    }
}

@media screen and (max-width: 700px) {
    .modalTagResponsive.ui.modal {
        width: 90vw !important;
    }
}

.company_perc_label {
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    padding: 0;
    background-color: white;
    color: black;
    line-height: 30px;
}

.footer-company-col {
    text-align: center;
    /*cursor: pointer;*/
}

.porfolioModalDiv {
    width: 100%;
    height: 90vh;
    overflow: hidden;
}

.imgPortfolio {
    object-fit: contain;
    height: 100%;
    width: 100%;
    top: 50%;
}

.imageSlider {
    cursor: pointer;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    object-fit: cover;
}

.textAreaPorfolio {
    border: none;
    z-index: 100;
    color: white;
    background: #000000cc 0 0 no-repeat padding-box;
    width: 100%;
    opacity: 1;
    position: absolute;
    bottom: 20%;
    font-size: 26px;
    font-family: Arial, sans-serif;
}
.publishCompany {
    cursor: pointer;
    fill: red;
    stroke: red;
}
.unpublishCompany {
    cursor: pointer;
    fill: green;
    stroke: green;
}

.dataIcons {
    cursor: pointer;
    fill: black;
}
.dataIconsDisabled {
    cursor: default;
    fill: rgb(112, 112, 112);
}
.dataIconsSettings {
    cursor: pointer;
    fill: white;
    stroke: black;
}

.dataIconsSettings > path {
    fill: black !important;
}

.exitButtonOnLoading {
    position: absolute !important;
    top: 6px !important;
    right: 10px !important;
    height: 25px !important;
    cursor: pointer;
    fill: transparent !important;
}

.defMediaDivCompany {
    height: 230px !important;
    width: 230px !important;
    border: none;
    border-radius: 50px;
    background-color: #e8e8e8;
    position: absolute;
    top: 35px;
    left: 140px;
}

.defMediaDivNoteditableCompany {
    height: 230px !important;
    width: 230px !important;
    border: none;
    background-color: white;
    margin-top: 40px;
    top: 35px;
    left: 140px;
}

.matching_icon {
    width: 70px;
    fill: rgba(0, 0, 0, 0.7);
}

.matching_score_wrapper {
    height: 0;
    width: 0;
    z-index: 100;
    position: relative;
    left: 50vw;
    transform: translate(-100px, 25px);
}
.matching_score_value {
    color: white;
    position: relative;
    left: 31px;
    top: -39px;
    font-size: 24px;
}
@media (max-width: 1200px) {
    .matching_icon {
        width: 70px;
    }
    .matching_score_wrapper {
        height: 0;
        width: 0;
        z-index: 100;
        position: relative;
        left: 100vw;
        transform: translate(-90px, 18px);
    }
    .matching_score_value {
        color: white;
        position: relative;
        left: 31px;
        top: -39px;
        font-size: 22px;
    }
}

@media (max-width: 500px) {
    .matching_icon {
        width: 50px;
    }
    .matching_score_wrapper {
        height: 0;
        width: 0;
        z-index: 100;
        position: relative;
        left: 100vw;
        transform: translate(-60px, 12px);
    }
    .matching_score_value {
        color: white;
        position: relative;
        left: 22px;
        top: -29px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
}
.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: white !important;
}

.modal-insert-document.ui.modal {
    background-color: white;
    width: 950px;
    height: auto;
}

@media screen and (max-width: 1100px) {
    .modal-insert-document.ui.modal {
        width: 90vw;
    }
}

.modal-insert-live.ui.modal {
    background-color: white;
    width: 950px;
    height: auto;
}

@media screen and (max-width: 1100px) {
    .modal-insert-live.ui.modal {
        height: 80vh;
        width: 95vw;
    }
}

@media screen and (max-width: 900px) {
    .modal-insert-live.ui.modal {
        height: 88vh;
        width: 95vw;
    }
}

@media screen and (max-width: 700px) {
    .modal-insert-live.ui.modal {
        height: 85vh;
        width: 95vw;
    }
}

@media screen and (max-width: 600px) {
    .modal-insert-live.ui.modal {
        height: 60vh;
        width: 95vw;
    }
}

@media screen and (max-width: 400px) {
    .modal-insert-live.ui.modal {
        height: 75vh;
        width: 95vw;
    }
}

@media screen and (max-width: 380px) {
    .modal-insert-live.ui.modal {
        height: 60vh;
        width: 95vw;
    }
}

@media screen and (max-width: 330px) {
    .modal-insert-live.ui.modal {
        height: 90vh;
        width: 95vw;
    }
}

.modal-insert-portfolio.ui.modal {
    width: 950px;
}

@media screen and (max-width: 1200px) {
    .modal-insert-portfolio.ui.modal {
        width: 95vw;
    }
}

@media screen and (max-width: 600px) {
    .modal-insert-portfolio.ui.modal {
        width: 90%;
    }
}
