.expo-footer {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;
    box-shadow: 0 -4px 8px #00000029;
    margin-top: auto;
    height: 300px;
}

@media (max-width: 768px) {
    .expo-footer {
        height: 200px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.expo-footer .chamber-logo {
    width: 100%;
}

.expo-footer .chamber-info {
    color: #000;
}

@media screen and (max-width: 50em) {
    .chamber-info {
        font-size: 10px;
    }
}

.footer-privacy {
    color: #be1522;
}

.footer-privacy:hover {
    color: rgb(77, 75, 75);
}
