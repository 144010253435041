.companyNameChamber {
    letter-spacing: 0;
    font: Bold 30px/40px Roboto;
    color: #fff;
    opacity: 1;
    padding: 9px 0px 20px;
    background: transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
}

.companyOtherFields {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0;
    font-size: 16px;
    color: #212121;
    opacity: 1;
}
.companyOtherFieldsBold {
    font-weight: bold;
    font-size: 16px !important;
}

.col-companyNameChamber {
    border: 4px solid #707070;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px -4px 8px #00000029;
}

.divider-company {
    min-width: 64px;
    width: 80px;
    border: 1px solid #e8e8e8;
    margin: 0px 0px 20px 0px;
    text-align: center;
}
