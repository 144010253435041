/* SG: 03/09/2020 */
.pointer {
    cursor: pointer;
    font-size: 24px;
}

@media screen and (max-width: 900px) {
    .pointer {
        font-size: 0;
    }
}

.b2b_header {
    background-color: #fff;
    padding: 0 0 0 25px;
    width: 100%;
    height: 60px;
    z-index: 100;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
}

.b2b_body {
    display: flex;
    height: calc(100% - 120px);
    width: 100%;
}

/* ereditati da aws sample */
.b2bcall {
    display: flex;
    flex-direction: column;
    background: #232323;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.b2bcall.isModeTransitioning::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #232323;
    z-index: 10;
}

/*
Posizioniamo il video dell'utente in alto a dx
Siamo obbligati ad usare la position absolute perchè l'header lo si eredita
dal componente NavBarUser dove non abbiamo controllo
*/
.localVideoWrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 150;
    background-color: #121212;
}

/*
Posizioniamo il footer in fondo all apagina
*/
.b2b_footer {
    position: fixed;
    transition: 500ms;
    bottom: 0;
    color: white;
    background-color: black;
    height: 60px;
    padding-top: 10px;
    z-index: 1000;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.b2b_footer.hidden {
    transition: 500ms;
    bottom: -60px;
}
.b2b_language {
    display: flex;
    right: 350px;
    position: absolute;
}
.contentVideoWrapper {
    display: none;
    flex: 1 1 auto;
    overflow-y: hidden;
}

.b2bcall.screenShareMode .contentVideoWrapper {
    display: none !important;
}

.b2bcall.isContentShareEnabled .contentVideoWrapper {
    display: flex;
}

.remoteVideoGroupWrapper {
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
}

.b2bcall.roomMode.isContentShareEnabled .remoteVideoGroupWrapper {
    flex: 0 0 321px; /* 1/6 di video per la colonna con i tile dei partecipanti */
}

.b2bcall.screenShareMode .controls {
    z-index: 1;
}

.b2bcall.screenShareMode .right {
    display: none;
}

.titleWrapper {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #232323;
}

.title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
}

.label {
    font-size: 0.8rem;
    color: var(--color_silver_chalice);
}

.deviceSwitcher {
    flex: 0 1 auto;
}

.roster {
    flex: 1 1 auto;
    overflow-y: scroll;
    height: 50%;
}

.chat {
    flex: 1 1 auto;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.modal {
    outline: none;
}

.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0.5rem);
}

.chat-video-col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    background: #121212;
    height: 60px;
    position: absolute;
    top: 0;
    right: 107px;
    z-index: 150;
}

.drawer-video-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 107px;
    background: #121212;
    height: 60px;
    text-align: center;
    color: white;
    position: absolute;
    top: 0;
    right: 214px;
    cursor: pointer;
    z-index: 150;
}

.drawer-video {
    box-sizing: border-box;
    position: absolute;
    z-index: 50;
}

.drawer-video .ant-drawer-wrapper-body {
    background-color: #e8e8e8;
}
