.contentVideo {
    display: block;
    background-color: #000;
    width: 100%;
    height: 100%;
}

.contentVideo .video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
