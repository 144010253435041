.expo-burger-item {
    /*display: flex;*/
    cursor: pointer;
    width: 400px;
    height: 120px;
    background-color: white;
    color: black;
}

.expo-burger-item a:link,
.expo-burger-item a:visited {
    color: black;
}

.expo-drawer-header {
    height: calc(100% - 120px) !important;
    z-index: 1001 !important;
    top: 120px !important;
    width: 400px;
}

.headerButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    float: right;
    height: 40px;
    margin: 0 20px;
}

.headerButtonXS,
.headerButtonPopover {
    display: none !important;
}

.headerButtonPopover {
    background-color: white;
}

.headerButton_p {
    min-height: 32px;
    color: black;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
}

.headerLogo {
    padding: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
}

@media (max-width: 768px) {
    .headerLogo {
        padding: 25px 0 !important;
    }
}

.headerBar {
    background-color: transparent;
    width: 100%;
    height: 180px;
    box-shadow: 0 4px 8px #00000029;
    padding: 0 45px;
    margin-bottom: 12px;
}

.expo-burger-item-image,
.expo-burger-item-text {
    display: flex !important;
    align-items: center;
    height: 100%;
    padding: 0 10px;
}

.expo-burger-item-image {
    justify-content: center;
}

.chamberName a,
.chamberName a:link,
.chamberName a:visited,
.chamberName a:active {
    color: #000;
}

.partial-border:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: calc(100% - 20px);
    border-bottom: 1px solid black;
}

@media (max-width: 768px) {
    .expo-drawer-header {
        height: calc(100% - 90px) !important;
        top: 90px !important;
        width: 100%;
    }

    .expo-burger-item {
        width: 100%;
        height: 150px;
    }
}

@media (max-width: 767px) {
    .headerButtonLG {
        display: none !important;
    }

    .headerButtonXS {
        display: flex !important;
    }

    .headerButtonPopover {
        display: flex !important;
        width: 100vw !important;
        margin: 0;
    }

    .headerButton {
        width: 50px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .headerButtonLG {
        display: none !important;
    }

    .headerButtonXS {
        display: flex !important;
    }

    .headerButtonPopover {
        display: flex !important;
        width: 350px !important;
    }

    .headerButton {
        width: 50px;
    }
}

.ellipsis-icon-menu {
    font-size: 30px;
}

@media screen and (max-width: 40em) {
    .ellipsis-icon-menu {
        font-size: 20px;
    }
}

/*.burger-menu-popover {
	padding-top: 30px;
	width: 70px;
}

.burger-menu-popover .ant-popover-inner-content {
	padding: 0;
}

.burger-menu-popover .ant-popover-arrow {
	display: none;
}

.burger-menu-popover .burger-menu-item:nth-last-child(1) div {
	border-bottom: none !important;
	margin-bottom: 0 !important;
}*/
