.editIcon {
    opacity: 0.5;
    cursor: pointer;
    height: 32px;
    width: 53px;
}

.editIcon:hover {
    opacity: 1;
}

.inputField {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #000;
    height: 36px;
}

.inputField:hover {
    border-radius: 0;
    border-color: #000 !important;
    border: none;
    border-bottom: 1px solid #000;
    background-color: #0000000c;
    outline: 0;
    box-shadow: none;
}

.description-info-panel {
    padding: 35px 110px 0 110px;
}

@media screen and (max-width: 50em) {
    .description-info-panel {
        padding: 35px 20px 0 20px;
    }
}
