.plusButton {
    position: absolute;
    width: 40px;
    opacity: 0.4;
    bottom: 10%;
    right: 10%;
}

.emptyShowCasePlusButton {
    right: 30%;
    bottom: 32px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
    z-index: 100;
}

.emptyPlusButton {
    right: 25%;
    bottom: 55px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
}

.emptyPlusButtonVideo {
    right: 25%;
    bottom: 55px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
}

.plusButtonItem {
    right: 25%;
    bottom: 55px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
}

.plusButton:hover,
.emptyPlusButton:hover,
.plusButtonItem:hover {
    opacity: 1;
}
