.editIcon {
    opacity: 0.5;
    cursor: pointer;
    height: 32px;
    width: 53px;
}

.editIcon:hover {
    opacity: 1;
}

.editingButtonStandardPositionBlock {
    position: absolute;
    right: 5%;
    top: 5%;
    z-index: 2;
    display: block;
}

.editingButtonStandardPositionBlockImage {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}

.editingButtonStandardPositionNone {
    display: none;
}
