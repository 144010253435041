.descriptionVideoTextArea {
    border: none;
    z-index: 100;
    color: white;
    background: #000000cc 0 0 no-repeat padding-box;
    width: 100%;
    opacity: 1;
    font-size: 26px;
    font-family: Arial, sans-serif;
}

.descriptionVideoContainer {
    border: none;
    z-index: 100;
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.inputTextVideoContainer {
    height: 30px;
}

.videoEditor {
    width: 100%;
    background-color: #ffffff;
    opacity: 1;
}

.uploadButtonContainer {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.uploadProgress > .ant-progress {
    width: 250px !important;
    height: 250px !important;
}

.uploadProgress > .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;
}
.uploadButton_red {
    cursor: pointer;
    width: 320px;
    fill: red;
}

.uploadButton_black {
    cursor: pointer;
    width: 50%;
    fill: #707070;
    margin: 10px 0px;
}

.uploadProgress {
    width: 320px;
    height: 320px;
}

.videoTitleBar {
    height: 80px;
    font-size: 40px;
    background: black;
    width: 100%;
}

.videoTitleBarPar {
    font-size: 34px;
    color: white !important;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
    opacity: 0.9 !important;
}

.videoNameAndSize {
    position: absolute;
    bottom: 20px;
    font-size: 25px;
    left: 0;
    padding-left: 50px;
    font-family: 'Roboto', sans-serif !important;
}

.actionPar_red {
    font-size: 34px;
    font-weight: bold !important;
    color: red !important;
    font-family: 'Roboto', sans-serif !important;
    opacity: 0.8 !important;
    margin-bottom: 100px !important;
}

.actionPar_black {
    font-size: 34px;
    font-weight: bold !important;
    color: black !important;
    font-family: 'Roboto', sans-serif !important;
    opacity: 0.8 !important;
    margin-bottom: 100px !important;
}

@media (max-width: 576px) {
    .uploadButton_red,
    .uploadButton_black {
        width: 150px;
    }

    .actionPar_black,
    .actionPar_red {
        font-size: 18px;
        margin-bottom: 60px !important;
    }

    .videoNameAndSize {
        font-size: 15px;
        padding-left: 10px;
    }

    .uploadProgress {
        width: 150px !important;
        height: 150px !important;
    }

    .uploadProgress > .ant-progress {
        width: 150px !important;
        height: 150px !important;
    }

    .videoTitleBarPar {
        font-size: 24px;
        margin-top: 30px;
    }
}
