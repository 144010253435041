div.event_insert.panelHeaderContainer,
div.event_insert.form_container {
    background-color: #fff;
}

.event_insert.form_container {
    padding: 0 135px;
    min-height: 480px;
}

.event_insert .event_insert_description {
    font-size: 13px;
    margin-bottom: 30px;
}

.event_insert .event_insert_title {
    font-size: 14px;
    margin-bottom: 30px;
}

.event-guest-card {
    background-color: #fff;
    border-radius: 8px;
    width: 480px;
    height: 70px;
    padding: 20px 14px;
    margin-bottom: 20px;
}

.event_insert_panelTop {
    background-color: #fff;
    /*
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  height: 470px;
  */
}

.event_insert_panelForm {
    padding: 20px 60px 60px;
}

.event_insert_range_picker,
.event_insert_range_picker .ant-picker-input .ant-picker-input-active,
.event_insert_range_picker .ant-picker-focused,
.event_insert_range_picker:hover,
.event_insert_range_picker:active,
.event_insert_range_picker:focus {
    height: 40px;
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0;
    font-size: 14px;
    box-shadow: none;
}

.event_insert_range_picker > input {
    background-color: transparent;
}

.event_insert_range_picker:active,
.event_insert_range_picker:focus,
.event_insert_range_picker:hover {
    background-color: #0000000c;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.event_insert .selected-company > .content:nth-child(1) {
    padding-bottom: 0;
}

.event_insert .selected-company .ant-form-item {
    margin-bottom: 0;
}

.event_insert .selected-company .ant-form-item label {
    cursor: pointer;
    text-transform: uppercase;
}

.event_insert .selected-company .ant-form-item-label label::after {
    content: none !important;
}

.event_insert button[type='submit']:hover {
    border: none;
    background-color: #129a2d;
    color: #fff;
}

.event_insert_timezone {
    border: none !important;
    width: 255px !important;
}

.event_insert .ant-select-selector,
.event_insert .ant-select-selector:hover,
.event_insert .ant-select-selector:active,
.event_insert .ant-select-selector:focus {
    border: none !important;
    height: 40px;
    border-bottom: 1px solid #000 !important;
    border-radius: 0 !important;
    font-size: 14px;
}

.event_insert .ant-select.ant-select-lg.ant-select-single.ant-select-show-arrow.ant-select-focused,
.event_insert .ant-select-selector:active,
.event_insert .ant-select-selector:focus,
.event_insert .ant-select-selector:hover {
    background-color: #0000000c;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* pinterest grid layout */
.masonry.grid {
    display: block;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

@media screen and (max-width: 800px) {
    .masonry.grid {
        column-count: 1;
    }
}

.masonry.grid > .column {
    width: 100% !important;
}

.square {
    height: 80px !important;
    object-fit: contain;
}

/*nuovo css*/

.expo-event {
    background-color: white;
}

.expo-event .bottom-shadow {
    box-shadow: 0 4px 8px #00000029;
    margin-bottom: 12px;
}

@media screen and (max-width: 130em) {
    .expo-event .panelForm {
        padding: 35px 110px 35px 110px;
    }
}

@media screen and (max-width: 80em) {
    .expo-event .panelForm {
        padding: 40px 90px 30px 90px;
    }
}

@media screen and (max-width: 40em) {
    .expo-event .panelForm {
        padding: 30px 30px 20px 30px;
    }
}

.expo-event .timezone {
    border: none !important;
    width: 255px !important;
}

.expo-event .range_picker,
.expo-event .range_picker .ant-picker-input .ant-picker-input-active,
.expo-event .range_picker .ant-picker-focused,
.expo-event .range_picker:hover,
.expo-event .range_picker:active,
.expo-event .range_picker:focus {
    height: 40px;
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0;
    font-size: 14px;
    box-shadow: none;
}

.expo-event .range_picker > input {
    background-color: transparent;
}

.expo-event .ant-select-selector,
.expo-event .ant-select-selector:hover,
.expo-event .ant-select-selector:active,
.expo-event .ant-select-selector:focus {
    border: none !important;
    height: 40px;
    border-bottom: 1px solid #000 !important;
    border-radius: 0 !important;
    font-size: 14px;
}

.expo_event .fit_image {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.company .xg_tile_container {
    cursor: pointer !important;
}

.company .xg_tile_selected {
    cursor: pointer !important;
}
