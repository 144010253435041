.video-main-div {
    height: calc(100% - 60px) !important;
    width: 100%;
    background-color: #232323;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    overflow: hidden;
    z-index: 210;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.eventHeaderBar {
    width: 100%;
    background-color: #fff;
    color: #000;
    height: 60px !important;
    z-index: 200;
}

.drawer-shadow {
    -webkit-box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
        -12px 0 48px 16px rgba(0, 0, 0, 0.03);
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
        -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.no-sharing {
    height: 100%;
}

.sharing {
    /*padding: 0 0 60px;*/
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
}

#other-column {
    background-color: #232323;
}

#callLeft {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.video-top-bar {
    position: fixed;
    top: 0;
    right: 0;
    height: 60px;
    /*overflow: hidden;*/
    z-index: 250;
    width: 428px;
    /*background-color: #121212*/
}

/*.video-top-bar.barsHidden {
	transition: 500ms;
	top: 0;
}*/

.button-bar {
    height: 100%;
    width: 100%;
    /*float: left;
	overflow: hidden;*/
}

.preview-container {
    background-color: rgba(0, 0, 0, 0.6);
    float: left;
    height: 100%;
    width: 155px;
    max-width: 155px;
}

.call-video {
    width: 100%;
    height: 100%;
    line-height: 0;
    align-items: center;
    justify-content: center;
}

.call-video-ph {
    height: 100%;
    align-items: center;
    justify-content: center;
}

#local-video {
    z-index: 10;
}

#other-video-0,
#other-video-1 {
    z-index: 50;
}

#shared-video {
    z-index: 75;
    display: flex;
    justify-content: center;
}

.video-div {
    color: white;
    flex-grow: 1;
    max-height: 100%;
    height: 100%;
}

.footer-video-div {
    color: white;
    background-color: black;
    padding-top: 10px;
    height: 60px;
    z-index: 1000;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.footer-video-div.barsHidden {
    transition: 500ms;
    bottom: -60px;
}

.drawer-video-col {
    padding: 20px 0 0 0;
    width: 90px;
    background: #121212;
    height: 60px;
    text-align: center;
    color: white;
}

.drawer-video-preview {
    padding: 0;
}

.footer-video-col {
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.footer-presenting-col {
    align-self: center;
}

.footer-presenting-label {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 20px;
    text-align: right;
}

.footer-video-label {
    background-color: black !important;
    color: white !important;
    text-align: center;
}

.event-icons {
    border-radius: 50%;
    font-size: 24px;
    opacity: 1;
    text-align: center;
}

@media (max-width: 360px) {
    .video-div {
        height: 203px;
    }
}

@media (max-width: 768px) {
    .video-div {
        height: 432px;
    }
}

.localPH {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.localName {
    font-size: 20px !important;
}

.ant-layout {
    height: 100%;
}

.drawer-video {
    position: absolute;
    /*top: 60px;*/
}

.ant-layout-content {
    display: flex;
    flex-direction: column;
}

.event-sharing-div {
    width: 240px;
    height: 135px;
    z-index: 2000;
}

.event-videoVisible {
    display: block;
}

.event-videoNotVisible {
    display: none;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
}

.sharingIconStyle #bg {
    fill: green;
}

.notSharingIconStyle #bg {
    fill: #121212;
}

.cantSharingIconStyle #bg {
    fill: red;
}

.rosterLine {
    clear: both;
    height: 85px;
    padding: 10px 0 15px;
}

.attendeeImg {
    float: left;
    height: 100%;
}

.attendeeCircular {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: red;
    text-align: center;
}

.attendeeInitials {
    width: 100%;
    height: 100%;
    /* visibility: hidden; */
    color: white;
    font-weight: bold;
    font-size: large;
    text-transform: uppercase;
    line-height: 60px;
}

/*.attendeeInitials:first-letter {
	visibility: visible;
}*/

.attendeeNameRoster {
    margin: 0 0 5px;
    padding-left: 20px;
    font-weight: bold;
    font-size: 18px;
}
.attendeeCompanyRoster {
    padding-left: 20px;
    font-size: 12px;
}

.event-video-container {
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.callLeft-panel {
    transition: 500ms;
    width: 640px;
    height: 680px;
    background: #ffffff 0 0 no-repeat padding-box;
    border-radius: 5px;
    overflow: hidden;
}

.callLeft-panel-image {
    transition: 500ms;
    height: 400px;
    overflow: hidden;
}

.callLeft-panel-text {
    transition: 500ms;
    width: 100%;
    height: 170px;
}

.box-center-cont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.callLeft-panel-button {
    transition: 500ms;
    height: 110px;
    border-top: 1px solid #00000033;
}

.callLeft-button {
    transition: 500ms;
    width: 180px;
    height: 50px;
    background-color: black;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
}

.callLeft-button:hover {
    transition: 500ms;
    background-color: white;
    border-radius: 25px;
    border: 2px solid black;
    color: black;
}

.callLeft-panel-text p {
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    color: black;
    text-align: center;
    padding: 10px;
}

@media (max-width: 500px) {
    .callLeft-panel {
        transition: 500ms;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .callLeft-panel-image {
        transition: 500ms;
        width: 100%;
        height: auto;
    }

    .callLeft-panel-text {
        transition: 500ms;
        width: 100%;
        height: 170px;
    }

    .callLeft-panel-button {
        transition: 500ms;
        height: auto;
    }
}
