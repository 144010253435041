.b2b-call-login {
    width: 640px;
    height: 920px;
    background: #ffffff 0 0 no-repeat padding-box;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
}

.b2b-call-login-image {
    height: 400px;
    overflow: hidden;
}

.b2b-call-link-unico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.b2b-call-link-unico p {
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    color: black;
}

.b2b-call-div-button {
    height: 100px;
    border-top: 1px solid #00000033;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20px;
}

.b2b-call-button-close {
    width: 180px;
    height: 50px;
    background-color: black;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b2b-call-button-close:hover {
    background-color: white;
    border-radius: 25px;
    border: 2px solid black;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b2b-call-button-close.ant-btn:focus {
    background-color: white;
    border: 2px solid black;
    color: black;
}

.b2b-call-button-next {
    width: 180px;
    height: 50px;
    background-color: #159a2e;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b2b-call-button-next:hover {
    background-color: white;
    border-radius: 25px;
    border: 2px solid black;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b2b-call-button-next.ant-btn:focus {
    background-color: white;
    border: 2px solid black;
    color: black;
}

.b2b-call-button-accedi {
    width: 180px;
    height: 50px;
    background-color: #000000;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.b2b-call-button-accedi:hover {
    background-color: white;
    border-radius: 25px;
    border: 2px solid black;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b2b-call-button-accedi.ant-btn:focus {
    background-color: white;
    border: 2px solid black;
    color: black;
}
