body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e8e8e8 !important;
    -ms-overflow-style: none;
}

.fix {
    display: flex;
    flex-flow: row;
}

.blackMode {
    background-color: #000;
    color: #fff;
    padding: 8px 40px;
    height: auto;
    width: auto;
}
.languageMode {
    color: #fff;
    height: auto;
    width: auto;
}
.blackMode a,
.blackMode a:link,
.blackMode a:active,
.blackMode a:visited,
.blackMode a:hover {
    color: #fff;
}

.whiteMode {
    background-color: #fff;
    color: #000;
}

.blackLink a,
.blackLink a:link,
.blackLink a:active,
.blackLink a:visited,
.blackLink a:hover {
    color: #000;
}

.layout {
    background-color: #fff;
    width: 1495px;
    max-width: 100%;
    min-width: 800px;
    margin: 0 auto;
}

.layoutFullScreen {
    background-color: #fff;
    width: 100%;
}

.pageFooterContainer,
.panelHeaderContainer {
    height: 80px;
}

.topRoundCorner {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.panelHeader {
    height: 48px;
    line-height: 48px;
    text-align: center;
    outline: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.75);
}

.pageFooter {
    height: 48px;
    line-height: 48px;
    text-align: center;
    outline: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    -webkit-box-shadow: 0 6px 10px #00000067;
    box-shadow: 0 6px 10px #00000067;
}

.back-span {
    background-color: #000000a5;
    text-align: center;
    color: #fff;
    cursor: pointer;
    position: absolute;
    line-height: 30px;
    width: 100px;
    top: 30px;
}

/* OVERRIDE CSS ANTD */
.ant-spin-nested-loading > div > .ant-spin {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ant-carousel .slick-slide {
    background: #fff;
    color: #000;
}

textarea.ant-input,
.ant-input-search,
.ant-select,
.ant-select.ant-select-lg.ant-select-single.ant-select-show-arrow,
.ant-input.ant-input-lg {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #000;
}

textarea.ant-input:focus,
textarea.ant-input:hover,
.ant-input-search:focus,
.ant-input-search:hover,
.ant-input-affix-wrapper-focused,
.ant-select.ant-select-lg.ant-select-single.ant-select-show-arrow.ant-select-focused,
.ant-input.ant-input-lg:focus,
.ant-input.ant-input-lg:hover {
    border-radius: 0;
    border-color: #000;
    border: none;
    border-bottom: 1px solid #000;
    background-color: #0000000c;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-input-affix-wrapper-focused input,
.ant-input-search:focus input,
.ant-input-search:hover input {
    background-color: transparent;
}

.ant-btn.ant-btn-round:focus,
.ant-btn:not([disabled]):active,
.ant-btn:hover,
.ant-btn.ant-btn-round:hover:not([disabled]):active {
    border-color: #000;
    border-right-width: 1px !important;
    background-color: #fff;
    color: #000;
    outline: 0;
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}

.ant-scroll-number-only-unit {
    line-height: 20px !important;
}

.logoMode .banner .imageE {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    object-fit: contain;
}

.logoMode .banner {
    height: 50vh;
}

.videoCrop {
    width: 100%;
    object-fit: cover;
}

.videoHook {
    width: 100%;
    max-height: 765px;
    object-fit: contain;
}

pre.wrap {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -o-pre-wrap; /* Opera 4-6 */
    word-wrap: break-word;
    font-family: 'Roboto', serif;
    color: rgba(0, 0, 0, 0.65);
}

.topBarIconCol {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
}

.el_button {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
}

.scrollbar-colored {
    padding: 10px 40px 0px;
    margin-top: 10px;
    margin-right: 4px;
    overflow-y: auto;
    height: 335px;
}

.scrollbar-colored::-webkit-scrollbar-track {
    background-color: white;
}

.scrollbar-request::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.badge_request_b2b .ant-badge-status-dot,
.ant-badge-status-processing,
.ant-badge-status-processing::after,
.ant-scroll-number {
    width: 13px !important;
    height: 13px !important;
    background-color: #fff !important;
    border-color: #fff;
    overflow: inherit;
    position: absolute;
    box-shadow: 0 0 0 1px #fff !important;
}

.button_request_badge .ant-badge-status-dot,
.ant-badge-status-processing,
.button_request_badge .ant-badge-status-processing::after,
.ant-scroll-number,
.ant-badge-dot {
    background-color: #a72230 !important;
    border-color: #a72230;
    width: 8px !important;
    height: 8px !important;
    overflow: inherit;
    position: absolute;
    box-shadow: 0 0 0 1px #a72230 !important;
}

.label-tag .ant-form-item-label {
    white-space: normal !important;
}

.link_website {
    color: #000;
}

.link_website:hover {
    font-weight: bold;
}
.button-media {
    width: 250px;
    text-align: center;
    border: 4px solid #707070;
    margin: 10px 20px 10px 0px;
    cursor: pointer;
    border-radius: 20px;
}

.button-media-no-hover {
    width: 250px;
    text-align: center;
    border: 4px solid #707070;
    margin: 10px 20px 10px 0px;
    border-radius: 20px;
}

.modal-media {
    background: transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box !important;
    text-align: center;
    font-size: 30px !important;
    color: #fff !important;
    font-family: 'Roboto' !important;
}

.container-icon-media {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    background-color: #e3e3e3;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    fill: #444444;
}

.container-icon-media:hover {
    background-color: #707070;
    color: #ffffff;
    fill: #ffffff;
}

.icon-plus-media {
    width: 12%;
    margin: 5px;
}

.container-media-insert {
    padding: 20px 50px 40px;
    width: 100%;
}

.button_operation_request_media {
    border: 1px solid #eaeaea !important;
    padding: 25px 0px 25px 0px;
    background-color: #ffffff;
    width: 100%;
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: inset 0px 2px 7px #dedede;
    display: flex;
    justify-content: center;
}

.col-media-insert {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}

.col-media-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}
.container-icon-upload {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.col-media-insert {
    align-items: center;
}
.col-flex-end {
    justify-content: flex-end;
    display: flex;
}

.col-center {
    justify-content: center;
}

@media screen and (max-width: 800px) {
    .col-media-insert {
        align-items: center;
    }
    .container-icon-upload {
        display: block;
    }
    .col-flex-end {
        justify-content: center;
    }
}

.upload-icon-media {
    width: 50%;
    fill: #707070;
    margin: 10px 0px;
}

.button-hover-green {
    background-color: #000;
    color: #fff;
    padding: 8px 40px;
    height: auto;
    width: auto;
}

.button-hover-green:hover {
    background-color: green;
    color: #ffffff;
    border-color: green;
}

.ui.modal > .header {
    border-bottom: 0px !important;
}

.two-button-distance {
    display: flex;
    justify-content: center;
    margin: 5px 25px;
}

.icon-card-info-company {
    margin-right: 20px;
    width: 25px;
    height: 25px;
    fill: #7d7d7d;
}

.icon-orange .cls-1 {
    fill: #e37b44;
}

.section-shadow {
    box-shadow: 0 -4px 5px #00000029;
}

.collapse-border-radius {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.collapse-border-radius .ant-collapse-header {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.collapse-border-radius.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0px solid #000 !important;
}

.icon-selected {
    cursor: pointer;
    border-bottom: 2px solid #000;
}
