.xg_tile_main {
    height: 360px;
    width: 300px;
    margin: 10px;
}

.xg_tile_container {
    height: 300px;
    width: 300px;
    overflow: hidden;
    border-radius: 25px;
    border: 1px solid;
    position: relative;
}

.xg_tile_container video {
    border-radius: 25px;
}

.xg_tile_image {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.xg_tile_title {
    text-align: center;
    font-size: 15px;
    height: 50px;
    padding: 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', serif;
    font-weight: 500;
}

.doc_container {
    height: 110px;
    position: absolute;
    top: 50px;
    width: 300px;
    background-color: transparent;
}

.xg_tile_title_doc {
    text-align: center;
    font-size: 18px;
    font-family: 'Roboto', serif;
    color: white;
    background-color: transparent;
    font-weight: bold;
    word-wrap: break-word;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.xg_tile_label {
    width: 90px;
    height: 35px;
    position: absolute;
    top: 30px;
    background-color: green;
    line-height: 35px;
    text-align: center;
    color: white;
    font-weight: bolder;
    font-size: 22px;
}

.xg_tile_layer {
    transition: 500ms;
    background-color: #ffffff;
    /* opacity: 0; */
    width: 300px;
    height: 300px;
    border-radius: 25px;
    color: white;
    position: absolute;
}

.xg_tile_layer:hover {
    transition: 500ms;
    opacity: 1;
}

.xg_tile_layer_text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    padding: 25px;
    font-style: italic;
    font-weight: 400;
}

.xg_tile_elem {
    height: 100%;
}

.xg_tile_hover {
    transition: all 500ms ease;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 100%;
    bottom: 0;
    background-color: #212121c5;
    cursor: initial;
    box-sizing: border-box;
    border-radius: 25px;
}

.xg_tile_container:hover .xg_tile_hover {
    transition: all 500ms ease;
    height: 40%;
    opacity: 1;
}

.xg_tile_hover_mobile {
    position: absolute;
    opacity: 1;
    height: 40%;
    width: 100%;
    bottom: 0;
    background-color: #212121c5;
    cursor: initial;
    box-sizing: border-box;
    border-radius: 25px;
}

.xg_tile_container:hover .xg_tile_hover_mobile {
    height: 40%;
    opacity: 1;
}

.xg_tile_hover:hover .xg_tile_image {
    pointer-events: none;
}

/* selectable feature */
.xg_tile_selected {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    cursor: initial;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid #159a2e;
    background: url(../images/check-overlay.png) no-repeat;
    background-size: cover;
}

.matching_icon_xtensible_gallery {
    width: 55px;
    fill: rgba(0, 0, 0, 0.7);
}

.matching_score_wrapper_tile {
    height: 0;
    width: 0;
    z-index: 100;
    position: relative;
    left: 350px;
    transform: translate(-111px, 5px);
}
.matching_score_value_tile {
    color: white;
    position: relative;
    left: 23px;
    top: -32px;
    font-size: 18px;
}
@media (max-width: 1200px) {
    .matching_score_wrapper_tile {
        height: 0;
        width: 0;
        z-index: 100;
        position: relative;
        left: 335px;
        transform: translate(-95px, 5px);
    }
    .matching_score_value_tile {
        color: white;
        position: relative;
        left: 24px;
        top: -32px;
        font-size: 18px;
    }
}

@media (max-width: 1050px) {
    .matching_score_wrapper_tile {
        height: 0;
        width: 0;
        z-index: 100;
        position: relative;
        left: 335px;
        transform: translate(-100px, 5px);
    }
    .matching_score_value_tile {
        color: white;
        position: relative;
        left: 25px;
        top: -30px;
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .matching_icon_xtensible_gallery {
        width: 45px;
    }

    .matching_score_wrapper_tile {
        height: 0;
        width: 0;
        z-index: 100;
        position: relative;
        left: 335px;
        transform: translate(-175px, 5px);
    }
    .matching_score_value_tile {
        color: white;
        position: relative;
        left: 20px;
        top: -26px;
        font-size: 15px;
    }
}

.documentInSlider {
    width: 100%;
    height: 300px;
    z-index: 0;
}

.documentInSliderPPT {
    fill: #c45a3c;
}

.documentInSliderDOC {
    fill: #295bb7;
}

.documentInSliderPDF {
    fill: #dd3636;
}

@media (max-width: 500px) {
    .xg_tile_main {
        height: 252px;
        width: 210px;
        margin: 7px;
    }

    .xg_tile_container {
        height: 210px;
        width: 210px;
        border-radius: 25px;
    }

    .xg_tile_container video {
        border-radius: 25px;
    }

    .documentInSlider {
        width: 100%;
        height: 210px;
    }

    .doc_container {
        height: 78px;
        position: absolute;
        top: 35px;
        width: 210px;
        background-color: transparent;
    }
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
