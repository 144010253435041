.upload_main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0;
}

.ant-upload {
    width: 100% !important;
    height: 100% !important;
}

.upload_Insert_text {
    font-size: 20px;
}

.upload_main_div .ant-upload.ant-upload-select-picture-card:hover {
    border-color: beige !important;
}
