.mainLayoutNewPage {
    background-color: #e8e8e8 !important;
    -ms-overflow-style: none;
}

.mainLayoutNewPage::-webkit-scrollbar {
    display: none;
}

.headerBar {
    background-color: white;
    width: 100%;
    height: 180px;
    box-shadow: 0 4px 8px #00000029;
    padding: 0 45px;
}

.headerLogo {
    padding: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.headerButton {
    text-align: center;
    display: flex;
    align-items: center;
}

.chamberName {
    font-family: Roboto, sans-serif;
    font-size: 34px;
    text-align: center;
    font-weight: bolder;
    padding: 5px;
}

.companyTop {
    z-index: 150;
    box-shadow: 0 4px 8px #00000029;
    background-color: white;
}

.companyDetails {
    transition: 500ms;
    z-index: 100;
    background-color: white;
    padding: 20px 0;
}

.companyTopRow {
    box-shadow: 0 4px 8px #00000029;
}

.companyImageContainer {
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.companyImage {
    width: 100%;
}

.companyInfo {
    min-height: 600px;
    padding: 40px 100px;
}

.companyName {
    font-size: 34px;
    line-height: 34px;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
}

.companySocials {
    margin-top: 35px;
}

.companyDescription {
    padding: 35px 0;
    font-family: 'Roboto', sans-serif;
}

.companyTags p {
    float: left;
    padding: 10px;
    margin: 5px;
    background-color: #e8e8e8;
    border-radius: 20px;
}

.otherCompany {
    height: 550px;
    padding: 50px 145px;
}

.otherCompanyTitle {
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    font-family: 'Roboto', sans-serif;
}

.otherCompanyLogoLeft,
.otherCompanyLogoRight {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.otherCompanyLogos {
    height: 400px;
    background-color: #4aa061;
}

.askEvent {
    height: 200px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.askEventButton {
    transition: 500ms;
    width: 700px;
    height: 100px;
    background-color: black;
    color: white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    font-weight: bolder;
    font-family: 'Roboto', sans-serif;
}

.askEventButton:hover {
    transition: 500ms;
    color: black;
    background-color: white;
    border: 3px solid black;
}

.footerBar {
    height: 260px;
    background-color: black;
}

.matchingScore {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    color: white;
}

.addressLine {
    text-align: right;
}

/*
@media(max-width:576px) {

}
@media(min-width:576px) {

}
*/
@media (max-width: 768px) {
    .headerBar {
        height: 90px;
        padding: 0 10px;
    }

    .companyInfo {
        padding: 40px 25px;
    }

    .companyDetails {
        transition: 500ms;
        padding: 0;
    }

    .askEvent {
        transition: 500ms;
        margin: 0;
    }

    .askEventButton {
        transition: 500ms;
        width: 300px;
        height: 75px;
        font-size: 24px;
    }

    .footerBar {
        height: 200px;
    }

    .addressLine {
        text-align: center;
        padding-bottom: 20px;
    }
}

@media (max-width: 1000px) {
    .headerBar {
        height: 120px;
        padding: 0 10px;
    }
}

@media (max-width: 1200px) {
    .headerBar {
        height: 130px;
        padding: 0 10px;
    }
}

@media (max-width: 900px) {
    .headerBar {
        height: 120px;
        padding: 0 10px;
    }
}

@media (max-width: 700px) {
    .headerBar {
        height: 100px;
        padding: 0 10px;
    }
}

@media (max-width: 450px) {
    .headerBar {
        height: 80px;
        padding: 0 10px;
    }
}
/*
@media(min-width:992px) {

}
*/
@media (min-width: 1200px) {
    .companyImage {
        width: auto;
        height: 100%;
    }
}
/*
@media(min-width:1600px) {

}*/

.xg_tile_image video {
    /*cursor: pointer !important;*/
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    object-fit: cover;
}

.xg_tile_main {
    text-align: left;
}

.back-arrow-top-bar {
    font-size: 20px;
}

/*@media screen and (max-width: 500px){
	.back-arrow-top-bar{
		font-size: 20px;
	}
}*/

.carouselModelLive .slick-track {
    display: flex !important;
    align-items: center;
}

.arrow-modal-live {
    cursor: pointer;
    width: 60px;
}

@media screen and (max-width: 30em) {
    .arrow-modal-live {
        width: 30px;
    }
}

@media screen and (max-width: 50em) {
    .arrow-modal-live {
        width: 40px;
    }
}

.ant-carousel .slick-slide {
    background-color: transparent !important;
}

.eraseInSlider {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.eraseInSlider path {
    fill: rgb(255, 255, 255) !important;
}

.eraseInToolbar {
    width: 53px;
    height: 32px;
    left: 30px;
}

.textAreaShowCase {
    border: none;
    z-index: 100;
    color: white;
    background: #000000cc 0 0 no-repeat padding-box;
    width: 100%;
    opacity: 1;
    position: absolute;
    bottom: 20%;
    font-size: 26px;
    font-family: Arial, sans-serif;
}

.imageSliderContainer {
    overflow: hidden;
    width: 230px !important;
    height: 230px !important;
    border-radius: 50px;
}

.imageSlider {
    cursor: pointer;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    object-fit: cover;
}

.plusButtonPortfolio {
    right: 10%;
    bottom: 32px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
}
.imgPortfolio {
    object-fit: contain;
    height: 100%;
    width: 100%;
    top: 50%;
}

.emptyShowCasePlusButtonPortfolio {
    right: 30%;
    bottom: 32px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
}

.emptyPlusButtonPortfolio {
    right: 150px;
    position: absolute;
    width: 40px;
    opacity: 0.4;
    bottom: 120px;
}

.plusButtonItemPortfolio {
    position: absolute;
    width: 40px;
    opacity: 0.4;
    bottom: 120px;
    right: 150px;
}

.plusButtonItemPortfolioProductEmpty {
    position: absolute;
    width: 40px;
    opacity: 0.4;
    bottom: 30px;
    right: 150px;
}

.plusButtonItemPortfolio:hover,
.emptyPlusButtonPortfolio:hover {
    opacity: 1;
}
.showItemsVideoCarusel {
    height: 230px !important;
    width: 230px !important;
    overflow: hidden;
    border-radius: 50px;
}

.modal-insert-video.ui.modal {
    background-color: white;
    width: 950px;
    height: auto;
}

@media screen and (max-width: 1100px) {
    .modal-insert-video.ui.modal {
        width: 90vw;
    }
}

.modal-insert-image.ui.modal {
    background-color: #ffffff;
    width: 950px;
    height: auto;
}

@media screen and (max-width: 1100px) {
    .modal-insert-image.ui.modal {
        width: 90vw;
    }
}

@media screen and (max-width: 400px) {
    .modal-insert-image.ui.modal {
        width: 95vw;
    }
}

@media screen and (max-width: 400px) {
    .modal-insert-image.ui.modal {
        width: 95vw;
    }
}

.modal-choose-image-video.visible.transition {
    justify-content: center;
    align-items: center;
}
