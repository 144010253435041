.avatar_wrapper {
    display: none;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.avatar_wrapper.enabled {
    display: flex;
}

.avatar_wrapper .avatar {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #008080;
    height: 50px;
    width: 50px;
    font-size: 25px;
    border: 2px solid #008080;
}

.avatar.small {
    transform: scale(1);
    font-size: 20px;
}

.avatar.medium {
    transform: scale(1);
    font-size: 25px;
    height: 65px;
    width: 65px;
}

.avatar.large {
    transform: scale(1);
    width: 100px;
    height: 100px;
    font-size: 30px;
}

.avatar.xlarge {
    transform: scale(1);
    width: 200px;
    height: 200px;
    font-size: 50px;
    border: 4px solid;
}
