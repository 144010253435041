.header-companies-joined {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    background-size: cover;
    background-position: 0, 100px;
}

@media screen and (max-width: 700px) {
    .header-companies-joined {
        height: 200px;
    }
}

.contain-row-header-companies-joined {
    background-color: rgba(0, 0, 0, 0.45);
    min-height: 400px;
    width: 100%;
}

@media screen and (max-width: 700px) {
    .contain-row-header-companies-joined {
        min-height: 200px;
    }
}

.title-header-companies-joined {
    color: #fff;
    font-size: 60px;
}

@media screen and (max-width: 700px) {
    .title-header-companies-joined {
        font-size: 25px;
    }
}

.subtitle-header-companies-joined {
    color: #fff;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 700px) {
    .subtitle-header-companies-joined {
        font-size: 16px;
    }
}

.div-companies-last-joined {
    margin-top: 30px;
    padding-left: 300px;
    padding-right: 300px;
}

@media screen and (max-width: 1500px) {
    .div-companies-last-joined {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 500px) {
    .div-companies-last-joined {
        padding-left: 0;
        padding-right: 0;
    }
}

.card-companies-joined {
    width: 400px;
}

@media screen and (max-width: 1500px) {
    .card-companies-joined.ui.fluid.card {
        width: 350px;
    }
}

@media screen and (max-width: 500px) {
    .card-companies-joined.ui.fluid.card {
        width: 290px;
    }
}

.button-search-companies.blackMode {
    font-size: 20px;
    height: 50px;
    text-transform: uppercase;
}

@media screen and (max-width: 500px) {
    .button-search-companies.blackMode {
        font-size: 13px;
        height: 40px;
    }
}

.radio-button-companies-search .ant-radio:hover .ant-radio-inner {
    border-color: black;
}

.radio-button-companies-search .ant-radio-checked .ant-radio-inner {
    border-color: black;
}

.radio-button-companies-search .ant-radio-inner:after {
    border-color: black;
    background-color: black;
}

.input-search-companies.ant-input-search {
    border-color: black;
    border: 1px solid;
    border-radius: 10px;
}

.input-search-companies:hover.ant-input-search {
    border-color: black;
    border: 1px solid;
    border-radius: 10px;
}
