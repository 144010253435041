.profile2 {
    margin-left: 4%;
    width: 180px;
    height: 180px;
    overflow: hidden;
    z-index: 1;
    background-color: white;
    outline: 4px solid #ebebeb;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 20px;
}

@media screen and (max-width: 500px) {
    .profile2 {
        margin: 10px;
        width: 120px;
        height: 120px;
    }
}

.icon-arrow-collapse {
    font-size: 22px !important;
    left: 62% !important;
}

@media screen and (max-width: 1700px) {
    .icon-arrow-collapse {
        left: 67% !important;
    }
}

@media screen and (max-width: 800px) {
    .icon-arrow-collapse {
        left: 70% !important;
    }
}

@media screen and (max-width: 700px) {
    .icon-arrow-collapse {
        left: 75% !important;
    }
}

@media screen and (max-width: 400px) {
    .icon-arrow-collapse {
        left: 80% !important;
    }
}

.button-green {
    background-color: green !important;
}

.button-request {
    width: 180px !important;
}

.request_card_header {
    padding: 15px 0px 25px 0px;
    background-color: #ffffff;
}

.arrow-request {
    width: 60px;
}

@media screen and (max-width: 500px) {
    .arrow-request {
        width: 40px;
    }
}

.contain-company-request {
    border: 4px solid #707070;
    margin: 30px 0px;
    border-radius: 16px;
    box-shadow: 0px -4px 8px #00000029;
}

.header-request {
    background: transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font: Bold 30px Roboto;
    text-align: center;
    padding: 9px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media screen and (max-width: 600px) {
    .header-request {
        font: Bold 20px Roboto;
    }
}

.contain-icon-pause-save {
    position: absolute;
    left: 92% !important;
}

@media screen and (max-width: 1600px) {
    .contain-icon-pause-save {
        left: 89% !important;
    }
}

@media screen and (max-width: 600px) {
    .contain-icon-pause-save {
        left: 88% !important;
    }
}

.icon-pause-save {
    width: 50px;
    fill: #fff;
}

@media screen and (max-width: 600px) {
    .icon-pause-save {
        width: 35px;
    }
}

@media screen and (max-width: 500px) {
    .icon-pause-save {
        width: 25px;
    }
}

.button_operation_request {
    border: 1px solid #eaeaea !important;
    padding: 25px 0px 25px 0px;
    background-color: #ffffff;
    width: 100%;
    margin: auto;
}

.container-info-company {
    margin: 0 15px;
    margin: 0px 15px;
    font-size: 18px;
}

@media screen and (max-width: 500px) {
    .container-info-company {
        margin: 0px 5px;
        font-size: 15px;
    }
}

.name-company-request {
    font-size: 24px;
}

@media screen and (max-width: 500px) {
    .name-company-request {
        font-size: 20px;
    }
}

.title-request {
    margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
    .title-request {
        margin-bottom: 0px;
    }
}

.container_event_collapse {
    padding: 20px 50px !important;
    max-height: 400px;
    height: auto;
    overflow-y: auto;
    margin-top: 10px;
    margin-right: 4px;
}

@media screen and (max-width: 500px) {
    .container_event_collapse {
        padding: 20px !important;
    }
}
