.signup-carousel-page {
    min-height: 400px;
    padding: 25px 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

.signup-header {
    height: 150px;
}

.signup-header .img-signup-header {
    height: 100px;
    width: auto;
}

/*
 * ACCORDION STYLES
*/
.ui.label {
    border-radius: 0;
}
.signup-accordion-title {
    padding: 0 !important;
}

.signup-accordion-title .label {
    width: 100%;
    text-transform: uppercase;
    font-size: 20px;
    color: #000000;
    border-bottom: 2px solid #000000;
    border-radius: 0;
    background-color: transparent;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, transparent 50%, #000000 50%);
    transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;
}

.active.signup-accordion-title .label,
.signup-accordion-title .label:hover {
    color: #fff;
    background-position: 0 100%;
    border: 0;
}

.signup-accordion-title .detail:hover {
    color: #fff;
}

.signup-accordion-title .detail {
    float: right;
    opacity: 1 !important;
}

.signup-accordion-content {
    background-color: #f6f6f6;
}

.signup-accordion-content .ant-form-item {
    margin-bottom: 0;
}

.signup-accordion-content .ant-form-item-label label {
    font-size: 20px;
    line-height: 24px;
    padding-left: 20px;
    height: auto;
}

.signup-accordion-content .ant-form-item-label label::after {
    content: none !important;
}

.signup-accordion-content:nth-last-child(1) .ui.list > .item:nth-last-child(1) {
    padding-bottom: 20px;
}

.ant-switch-small.ant-switch.ant-switch,
.ant-switch-small.ant-switch.ant-switch-checked {
    float: right;
    margin-right: 20px;
}

@media screen and (max-width: 1300px) {
    .ant-switch-small.ant-switch.ant-switch,
    .ant-switch-small.ant-switch.ant-switch-checked {
        margin-right: 10px;
    }
}

.ant-switch-small.ant-switch.ant-switch-checked {
    background: #159a2e;
}

.checkbox-privacy {
    color: #000;
}

.checkbox-privacy:hover {
    color: rgb(77, 75, 75);
}

.card-chamber-signup.ui.card,
.ui.cards > .card {
    width: 540px !important;
    height: 210px !important;
    border-radius: 16px;
}

.checkbox-privacy-signup .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000 !important;
    border-color: #000 !important;
}

.checkbox-privacy-signup .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #000 !important;
}

.panelTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font: Bold 30px/40px Roboto;
}

.signup-carousel-page .ant-input {
    background-color: transparent;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000a5 !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000000a5 !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000000a5 !important;
}

.ant-select-selection-placeholder {
    opacity: 1 !important;
    color: #000000a5 !important;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.ant-input:focus {
    background-color: transparent !important;
}

.card-signup-chamber .ui.card > .extra,
.ui.cards > .card > .extra {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
}

.p-card-header {
    font-size: 24px;
    margin-bottom: 8px;
}

@media screen and (max-width: 700px) {
    .p-card-header {
        font-size: 20px;
    }
}

.ant-input-group-addon{
    background-color: transparent !important;
    border: 0px !important;
    border-bottom: 1px solid #000 !important;
    color: #4E4E4E !important;
    font-size: 16px !important;
}