.remoteVideoGroup {
    display: grid;
    height: 100%;
}
.remoteVideoGroupWrapper.scroll {
    overflow-y: overlay;
}

.remoteVideoGroup.isContentShareEnabled {
    display: flex;
    flex-direction: column;
}

/* per rispettare i 16/9 del video */
.remoteVideoGroup.roomMode.isContentShareEnabled .remoteVideo {
    height: 202.5px;
    min-height: 202.5px;
    max-height: 202.5px;
}

/* Content share in room mode */
/* Un partecipante alla call condivide contenuti */
/*
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-0,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-1,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-2,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-3,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-4,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-5,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-6,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-7,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-8,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-9,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-10,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-11,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-12,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-13,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-14,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-15,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-16 {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
}
*/

/* Screen share mode */
/*
.remoteVideoGroup.screenShareMode.remoteVideoGroup-1,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-2,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-3,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-4 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
}

.remoteVideoGroup.screenShareMode {
    padding: 0 0.25rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);
}
*/

/* Room mode */
/* un tile video */
.remoteVideoGroup.remoteVideoGroup-0,
.remoteVideoGroup.remoteVideoGroup-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

/* due tile video */
.remoteVideoGroup.remoteVideoGroup-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

/* tre tile video */
.remoteVideoGroup.remoteVideoGroup-3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}
.remoteVideoGroup-3 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-3 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-3 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: span 2;
}

/* quattro tile video */
.remoteVideoGroup.remoteVideoGroup-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

/* cinque tile video */
.remoteVideoGroup.remoteVideoGroup-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}
.remoteVideoGroup-5 .remoteVideo.enabled.grid-position-1 {
    grid-column-end: span 2;
}
.remoteVideoGroup-5 .remoteVideo.enabled.grid-position-2 {
    grid-column-end: span 2;
}
.remoteVideoGroup-5 .remoteVideo.enabled.grid-position-3 {
    grid-column-start: span 2;
}
.remoteVideoGroup-5 .remoteVideo.enabled.grid-position-4 {
    grid-column-start: 2;
    grid-column-end: span 2;
}
.remoteVideoGroup-5 .remoteVideo.enabled.grid-position-5 {
    grid-column-start: 4;
    grid-column-end: span 2;
}

/* sei tile video */
.remoteVideoGroup.remoteVideoGroup-6 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

/* sette tile video */
.remoteVideoGroup.remoteVideoGroup-7 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 2;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: 3;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 2;
    grid-column-start: 1;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 2;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: 3;
}
.remoteVideoGroup-7 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 3;
    grid-column-start: 2;
}

/* otto tile video */
.remoteVideoGroup.remoteVideoGroup-8 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: span 2;
}
.remoteVideoGroup-8 .remoteVideo.enabled.grid-position-8 {
    grid-row-start: 3;
    grid-column-start: 4;
    grid-column-end: span 2;
}

/* nove tile video */
.remoteVideoGroup.remoteVideoGroup-9 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

/* dieci tile video */
.remoteVideoGroup.remoteVideoGroup-10 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 2;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: 3;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 1;
    grid-column-start: 4;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 1;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: 2;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 2;
    grid-column-start: 3;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-8 {
    grid-row-start: 2;
    grid-column-start: 4;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-9 {
    grid-row-start: 3;
    grid-column-start: 2;
}
.remoteVideoGroup-10 .remoteVideo.enabled.grid-position-10 {
    grid-row-start: 3;
    grid-column-start: 3;
}

/* undici tile video */
.remoteVideoGroup.remoteVideoGroup-11 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 1;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 2;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-8 {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-9 {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-10 {
    grid-row-start: 3;
    grid-column-start: 4;
    grid-column-end: span 2;
}
.remoteVideoGroup-11 .remoteVideo.enabled.grid-position-11 {
    grid-row-start: 3;
    grid-column-start: 6;
    grid-column-end: span 2;
}

/* dodici tile video */
.remoteVideoGroup.remoteVideoGroup-12 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

/* tredici tile video */
.remoteVideoGroup.remoteVideoGroup-13 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 1;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 2;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-8 {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-9 {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-10 {
    grid-row-start: 3;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-11 {
    grid-row-start: 3;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-12 {
    grid-row-start: 3;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-13 .remoteVideo.enabled.grid-position-13 {
    grid-row-start: 4;
    grid-column-start: 4;
    grid-column-end: span 2;
}

/* quattordici tile video */
.remoteVideoGroup.remoteVideoGroup-14 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 2;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: 3;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 1;
    grid-column-start: 4;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 1;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: 2;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 2;
    grid-column-start: 3;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-8 {
    grid-row-start: 2;
    grid-column-start: 4;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-9 {
    grid-row-start: 3;
    grid-column-start: 1;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-10 {
    grid-row-start: 3;
    grid-column-start: 2;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-11 {
    grid-row-start: 3;
    grid-column-start: 3;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-12 {
    grid-row-start: 3;
    grid-column-start: 4;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-13 {
    grid-row-start: 4;
    grid-column-start: 2;
}
.remoteVideoGroup-14 .remoteVideo.enabled.grid-position-14 {
    grid-row-start: 4;
    grid-column-start: 3;
}

/* quindici tile video */
.remoteVideoGroup.remoteVideoGroup-15 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-1 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-2 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-3 {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-4 {
    grid-row-start: 1;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-5 {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-6 {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-7 {
    grid-row-start: 2;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-8 {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-9 {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-10 {
    grid-row-start: 3;
    grid-column-start: 3;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-11 {
    grid-row-start: 3;
    grid-column-start: 5;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-12 {
    grid-row-start: 3;
    grid-column-start: 7;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-13 {
    grid-row-start: 4;
    grid-column-start: 2;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-14 {
    grid-row-start: 4;
    grid-column-start: 4;
    grid-column-end: span 2;
}
.remoteVideoGroup-15 .remoteVideo.enabled.grid-position-15 {
    grid-row-start: 4;
    grid-column-start: 6;
    grid-column-end: span 2;
}

/* sedici tile video */
.remoteVideoGroup.remoteVideoGroup-16 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}
