.controls_wrapper {
    display: flex;
    flex: 1;
    margin: 0 60px;
}

.controls_wrapper::before,
.central_wrapper,
.right_wrapper {
    content: '';
    flex-basis: 100%;
}

.central_wrapper {
    display: flex;
    justify-content: center;
}

.right_wrapper {
    display: flex;
    justify-content: end;
}

.right_wrapper span {
    padding-right: 30px;
    text-transform: uppercase;
}

.controls_wrapper .btn {
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.controls {
    display: block;
    position: relative;
}

.controls.screenShareMode {
    width: calc(var(--local_video_container_height) - 0.5rem);
    height: calc(var(--local_video_container_height) - 0.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.15s;
}

.controls.screenShareMode:hover {
    opacity: 1 !important;
}

.controls.screenShareMode.videoEnabled {
    opacity: 0;
}

.controls.screenShareMode.audioMuted {
    opacity: 1;
}

.controls.screenShareMode.audioMuted.videoEnabled .micMuted {
    display: flex;
}

.controls.screenShareMode.audioMuted.videoEnabled .muteButton {
    background-color: var(--color_thunderbird);
}

.sharingIconStyle #bg {
    fill: green;
}

.notSharingIconStyle #bg {
    fill: #121212;
}

.cantSharingIconStyle #bg {
    fill: red;
}

.settingsIconStyle {
    color: #fff;
    background-color: #121212;
    width: 45px;
    border-radius: 50%;
    cursor: pointer;
}

.deviceSelect {
    /* styling */
    background-color: white;
    border: thin solid #121212;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    min-width: 400px;

    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.deviceSelect.minimal {
    background-image: linear-gradient(45deg, transparent 50%, #121212 50%),
        linear-gradient(135deg, #121212 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}

/*
.deviceSelect.minimal:focus {
    background-image: linear-gradient(45deg, black 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, black 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}
*/

.deviceSelect:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
