.main-arrow {
    transition: 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-arrow path {
    transition: 500ms;
    fill: black;
}

.main-arrow-down path {
    transition: 500ms;
    fill: white;
}

.true-arrow {
    transition: 500ms;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.rotated {
    transition: 500ms;
    transform: rotate(90deg);
}
