.expo-event .invitation-link {
    font-size: 14px;
    border: 2px solid #31a58f;
}

.expo-event .invitation-link,
.expo-event .invitation-link a,
.expo-event .invitation-link a:link,
.expo-event .invitation-link a:active,
.expo-event .invitation-link a:visited,
.expo-event .invitation-link a:hover {
    color: #31a58f;
}

.ant-row .expo-event {
    padding: 30px 80px 0 80px;
}

@media screen and (max-width: 1600px) {
    .ant-row .expo-event {
        padding: 0;
    }
}

@media screen and (max-width: 70em) {
    .ant-row .expo-event {
        padding: 10px 20px 0 20px;
    }
}
