.circularPH {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /*position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);*/
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.videoPin {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 200;
    width: 25px;
    height: 25px;
}

#shared-video-stream {
    height: auto;
}

.attendeeName {
    color: white;
    font-weight: bolder;
    margin-bottom: -100%;
    /*position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);*/
    font-size: 30px;
}

.xxlarge {
    transform: /*translate(-50%, -50%)*/ scale(4);
}

.xlarge {
    transform: /*translate(-50%, -50%)*/ scale(3);
}

.large {
    transform: /*translate(-50%, -50%)*/ scale(2.25);
}

.medium {
    transform: /*translate(-50%, -50%)*/ scale(2);
}

.small {
    transform: /*translate(-50%, -50%)*/ scale(1.5);
}

.xsmall {
    transform: /*translate(-50%, -50%)*/ scale(1.25);
}

.xxsmall {
    transform: /*translate(-50%, -50%)*/ scale(1);
}

.xxxsmall {
    transform: /*translate(-50%, -50%)*/ scale(0.5);
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
    .xxlarge {
        transform: /*translate(-50%, -50%)*/ scale(3.2);
    }

    .xlarge {
        transform: /*translate(-50%, -50%)*/ scale(2.4);
    }

    .large {
        transform: /*translate(-50%, -50%)*/ scale(1.8);
    }

    .medium {
        transform: /*translate(-50%, -50%)*/ scale(1.6);
    }

    .small {
        transform: /*translate(-50%, -50%)*/ scale(1.2);
    }

    .xsmall {
        transform: /*translate(-50%, -50%)*/ scale(1);
    }

    .xxsmall {
        transform: /*translate(-50%, -50%)*/ scale(0.8);
    }

    .xxxsmall {
        transform: /*translate(-50%, -50%)*/ scale(0.5);
    }
}

.videoEmbed {
    height: 100%;
    z-index: 150;
    object-fit: cover;
    /*position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoFooter {
    z-index: 150;
    position: absolute;
    bottom: 0;
    padding: 5px;
    width: 100%;
}

.videoFooterPlate {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px 10px 5px 0;
    border-radius: 0 10px 10px 0;
    float: left;
    max-width: calc(100% - 10px);
    height: 60px;
    display: grid;
}

.videoFooterPlateSharing {
    color: white;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.3);
    padding-right: 10px;
    border-radius: 0 10px 10px 0;
    float: left;
    height: 25px;
    max-width: calc(100% - 10px);
}

.isSharer {
    padding: 0 10px;
    border-radius: 10px;
    height: 37px;
}

.videoFooterVolume {
    float: left;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px 0 0 10px;
    height: 60px;
    display: grid;
    align-items: center;
}

.videoFooterVolumeSharing {
    float: left;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px 0 0 10px;
    height: 25px;
    display: flex;
    align-items: center;
}

.videoFooterVolumeIndicator {
    position: relative;
    border-radius: 50%;
    background-color: #4aa061;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.videoFooterNation {
    /*background-color: rgba(0, 0, 0, 0.3);
	padding: 10px 15px;
	border-radius: 10px;*/
    width: 50px;
    float: right;
    margin: 8px 15px 0 0;
}

.videoName {
    font-size: 18px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
}

.videoNameSharing {
    font-size: 12px;
    line-height: 25px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
}

.isSharer .videoNameSharing {
    font-size: 22px;
    line-height: 37px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
}

.isSharer.isMobile .videoNameSharing {
    font-size: 14px;
}

.videoAgency {
    font-size: 12px;
}

/*#local-video-stream {
	transform: translate(-50%, -50%) rotateY(180deg)!important;
}*/
