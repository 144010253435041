.subscription-form {
    background-color: #e8e8e8;
}

.subscription-title {
    padding-top: 16px;
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    letter-spacing: 0;
    color: #212121;
}

.subscription-subtitle {
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0;
    color: #707070;
}

.switch-container {
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switch-container span {
    margin: 0 20px;
    font-size: 24px;
    cursor: pointer;
    color: grey;
}

.switch-container span.selected {
    color: #000;
    font-weight: bold;
    cursor: auto;
}

.button-container {
    justify-content: center;
}

.button-container button {
    font-size: 14px;
    font-weight: bold;
    align-items: center;
}

.button-container button[type='submit']:hover {
    border: none;
    background-color: #129a2d;
    color: #fff;
}

/* sovrascrive il colore blu dello switch selezionato */
.switch-container .ant-switch-checked,
.ant-switch {
    background-color: #a72203;
}

.paypal-container {
    margin: 20px 0;
}

.button-subscription {
    color: white;
    border-radius: 100px;
    padding: 0px 40px;
    height: 50px;
    font-size: 1.2rem;
    margin: 30px 0px 10px;
}

.button-subscription:hover {
    box-shadow: 0 6px 15px 0 #a72203;
    border-color: transparent;
}

.result-subscription .ant-result-subtitle {
    font-size: 24px;
}

.result-subscription .ant-result-title {
    font-size: 30px;
}
