.localVideo {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 107px;
    height: 60px;
}

.localVideo .video {
    display: none;
}

.localVideo .video.enabled {
    display: flex !important;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
