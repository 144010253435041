.userSliderContainer {
    left: 0;
    width: 100%;
    background: #e8e8e8 0 0 no-repeat padding-box;
    box-shadow: 0 -4px 8px #00000029;
    opacity: 1;
}

.companyDataTitle {
    text-align: center;
    font: Bold 30px/40px Roboto;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    padding: 9px 0px;
}

.companyDataTitleMainData {
    text-align: center;
    font: Bold 30px/40px Roboto;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    padding: 9px 0px 0px 0px;
}

.companyDataCreationDate {
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    padding: 0px 0px 10px 0px;
}

.companyDataCarousel {
    margin-top: 20px;
}

.userSliderLabel {
    position: absolute;
    right: 0;
    z-index: 100;
    bottom: 10px;
    background: #fff 0 0 no-repeat padding-box !important;
    padding: 0px !important;
}
.userCountryLabel {
    position: absolute;
    right: 20px;
    z-index: 100;
    bottom: 10px;
    background: #e8e8e8 0 0 no-repeat padding-box;
}

.companyDataInput {
    background: #e8e8e8 0 0 no-repeat padding-box;
}

.charFontFamily {
    font-family: Roboto, sans-serif;
}

.nameandsurname {
    font-weight: bold;
    font-size: 16px;
}

.mailandrole {
    font-style: italic;
    font-size: 16px;
}
.cancelButton {
    margin-top: 20px !important;
}
.subscriptionLabel {
    font: bold 18px Roboto !important;
    padding: 0 !important;
    width: 100%;
    height: 0 !important;
    margin-top: 20px !important;
}
.subscriptionLabelValue {
    font: italic 16px Roboto !important;
    color: black !important;
    padding: 0 !important;
    width: 100%;
    margin-left: 0;
    height: 0 !important;
}

.subscriptionInputName {
    background: #e8e8e8 0 0 no-repeat padding-box !important;
    color: black !important;
    font: 24px Roboto !important;
    padding-left: 0 !important;
}
.subscriptionInputNameCol {
    border-bottom: solid 1px black !important;
}
.subscriptionLabelValidPlan {
    color: black !important;
    font: 24px Roboto !important;
    padding: 0 !important;
}

.planDescriptionLabel {
    text-align: left;
}

.modalUserEditor {
    background-color: white;
    width: 55vw;
}

@media screen and (max-width: 700px) {
    .modalUserEditor {
        background-color: white;
        width: 95%;
    }
}

.form-company {
    border: 4px solid #707070;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px -4px 8px #00000029;
}
@media screen and (max-width: 1200px) {
    .form-company.ant-form,
    .form-chamber.ant-form,
    .col-companyNameChamber {
        margin-top: 40px;
    }
}

.rowCompanyDataTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rowCompanyDataTitle .divider-company {
    min-width: 64px;
    width: 80px;
    border: 1px solid #e8e8e8;
    margin: 0px 0px 20px 0px;
    text-align: center;
}

.col-companyData {
    border: 4px solid #707070;
    border-radius: 16px;
    background-color: #fff;
    height: auto;
    box-shadow: 0px -4px 8px #00000029;
}

.form-chamber {
    border: 4px solid #707070;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px -4px 8px #00000029;
}

.rowChamberDataTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.companyAccessPlan {
    text-align: center;
    font: Bold 34px/45px Roboto;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    padding-bottom: 20px;
}
