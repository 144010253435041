.custom-tooltip {
    border: 2px solid #000;
    border-radius: 5px;
    padding: 10px;
    background-color: #000;
    color: #fff;
    text-align: center;
}

.chart-label {
    font-size: 15px !important;
    margin-bottom: 0px;
}
.chart-dates {
    text-align: left;
    color: #000;
    padding: 20px 50px 0px 50px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
}
.recharts-active-dot circle {
    fill: #fff;
    stroke: #707070;
    stroke-width: 4px;
}

.sinistra {
    float: left;
}
