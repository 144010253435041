.videoNamePlateWrapper {
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 5px;
}

.videoNameplate {
    z-index: 1;
    align-items: center;
    max-width: 95%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--color_black_medium_opacity);
    backdrop-filter: blur(0.5rem);
}

.videoNameplate.roomMode {
    display: flex;
}

.videoNameplate.screenShareMode {
    display: none;
}

.videoNameplate.small {
    padding: 0.2rem 0.2rem 0.2rem 0.3rem;
    bottom: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
}

.videoNameplate.medium {
    padding: 0.25rem 0.3rem 0.25rem 0.5rem;
    bottom: 0.25rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
}

.videoNameplate.large {
    padding: 0.5rem 0.75em 0.5em 1rem;
    bottom: 0.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
}

.videoNameplate.roomMode.isContentShareEnabled {
    display: none;
}

.videoNameplate.roomMode.isContentShareEnabled.large {
    display: flex !important;
    padding: 0.2rem 0.2rem 0.2rem 0.3rem;
    bottom: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
}

.videoNameplate.screenShareMode.large {
    display: flex !important;
    padding: 0.2rem 0.2rem 0.2rem 0.3rem;
    bottom: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
}

.name {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
}

.muted {
    flex: 0 0 1.25rem;
    width: 1.25rem;
    text-align: center;
}

.videoNameplate.small .name + .muted,
.videoNameplate.medium .name + .muted {
    margin-left: 0.25rem;
}

.videoNameplate.large .name + .muted {
    margin-left: 0.5rem;
}

.videoNamePlateText {
    float: left;
    height: 60px;
    padding: 10px 10px 10px 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.5rem);
    border-radius: 0 10px 10px 0;
}

.videoNameplateVolume {
    float: left;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.5rem);
    border-radius: 10px 0 0 10px;
    height: 60px;
    display: grid;
    align-items: center;
}

.videoNamePlateVolumeIcon {
    width: 35px;
}
