.ImageEditorModal .banner > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: copy;
    padding: 0;
    margin: 0;
    min-width: auto;
}

.ImageEditorMedia .doka--menu {
    bottom: 20% !important;
    position: absolute !important;
}
.logoMode .doka--menu {
    bottom: unset !important;
    position: absolute !important;
}

.ImageEditorMedia .DokaOverlay > img {
    display: block;
    width: auto;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.ImageEditorModal {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.ImageEditorEvent {
    position: relative;
    top: 50%;
    height: 100%;
    /*transform: translateY(-10%);*/
}

.ImageEditorMedia {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    width: 100%;
}
